<template>
    <div class="broadBandDetails">
<!--        <van-nav-bar class="navbar" title="查看详情" fixed left-arrow @click-left="leftReturn"/>-->
        <rx-nav-bar title="查看保洁详情"></rx-nav-bar>
        <div class="dispatchText">
            申请详情
        </div>
        <div class="broadBandDetails-Apply">
            <div class="addressInfo">
                {{applyCleanDetails.roomDetailedAddress ? applyCleanDetails.roomDetailedAddress : '暂无'}}
            </div>
            <div class="rule"></div>
         <div class="divOne">
            <div class="leftDiv">
                <p>
                    <span class="spanOne" >申请人: </span>
                    <span class="spanTwo" >{{applyCleanDetails.applicationGlobaluserName ? applyCleanDetails.applicationGlobaluserName : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">订单来源: </span>
                    <span class="spanTwo">{{applyCleanDetails.fromType ? applyCleanDetails.fromType : '暂无'}}</span>
                </p>
            </div>
             <div class="rightDiv">
                 <p>
                     <span class="spanOne">保洁类型: </span>
                     <span class="spanTwo">{{applyCleanDetails.cleanType? applyCleanDetails.cleanType : '暂无'}}</span>
                 </p>
                 <p>
                     <span class="spanOne">联系方式: </span>
                     <span class="spanTwo">{{applyCleanDetails.applicationGlobaluserMoblile? applyCleanDetails.applicationGlobaluserMoblile : '暂无'}}</span>
                 </p>

             </div>
        </div>
        <div class="divTwo">

            <p>
                <span class="spanOne" >预约时间: </span>
                <span class="spanThree" >{{applyCleanDetails.appointmentTime? applyCleanDetails.appointmentTime : '暂无'}}</span>
            </p>
            <p>
                <span class="spanOne">申请原因: </span>
                <span class="spanTwo">{{applyCleanDetails.afterRentDesprition? applyCleanDetails.afterRentDesprition : '暂无'}}</span>
            </p>
        </div>
        </div>
        <!--     申请详情的图片  -->
        <div class="applyDetailImgs" v-if="applayImgList.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in applayImgList" :key="item.id">
                    <div class="applyDetailImg">
                    <img  :src="item" @click="onChangeApply(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{applayImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showApply" :images="applayImgList"  :start-position="start"></van-image-preview>
        <div class="dispatchText" v-if="applyCleanDetails.pieList.length>0">
            派单信息
        </div>
        <div class="broadBandDetails-dispatch"  v-for=" item in applyCleanDetails.pieList" :key="item.id" >
            <div class="divOne">
                <div class="leftDiv">
                    <p>
                        <span class="spanOne" >保洁姓名: </span>
                        <span class="spanTwo" >{{item.changeGlobaluserName ? item.changeGlobaluserName : '暂无'}}</span>
                    </p>

                </div>
                <div class="rightDiv">
                    <p>
                        <span class="spanOne" >联系方式: </span>
                        <span class="spanTwo" >{{item.changeGlobalMobile? item.changeGlobalMobile : '暂无'}}</span>
                    </p>

                </div>
            </div>
            <div class="divTwo">
                <p>
                    <span class="spanOne">预计费用: </span>
                    <span class="spanTwo">{{item.fee? item.fee+'元' : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">预计上门时间: </span>
                    <span class="spanThree">{{item.changeTime? item.changeTime : '暂无'}}</span>
                </p>
            </div>
        </div>

        <div class="dispatchText" v-if="applyCleanDetails.lastFee||applyCleanDetails.finishTime||applyCleanDetails.dealResult">
            完成信息
        </div>
        <div class="broadBandDetails-dispatch" v-if="applyCleanDetails.lastFee||applyCleanDetails.finishTime||applyCleanDetails.dealResult">
            <div class="address1">
            </div>
            <div class="divTwo">
                <p>
                    <span class="spanOne" >完成费用: </span>
                    <span class="spanTwo" >{{applyCleanDetails.lastFee ? applyCleanDetails.lastFee+'元' : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">完成时间: </span>
                    <span class="spanThree">{{applyCleanDetails.finishTime ? applyCleanDetails.finishTime : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne" >处理结果: </span>
                    <span class="spanTwo" >{{applyCleanDetails.dealResult ? applyCleanDetails.dealResult : '暂无'}}</span>
                </p>
            </div>
            </div>
        <!--     完成的图片  -->
        <div class="applyDetailImgs" v-if="finishImgList.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in finishImgList" :key="item.id">
                    <div class="applyDetailImg">
                    <img  :src="item" @click="onChangeFinished(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{finishImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showFinished" :images="finishImgList"  :start-position="start"></van-image-preview>


        <!--       验收DIV标题 -->
        <div class="dispatchText"  v-if="applyCleanDetails.afterrentHisotryList.length>0">验收记录</div>
        <!--   "     验收详情DIV-->
        <div v-if="applyCleanDetails.afterrentHisotryList.length>0" >
            <div class="applyDetail" v-for="(item,i) in applyCleanDetails.afterrentHisotryList" :key="i"  >
                <!--       验收主体内容 -->
                <div class="divOne">
                    <div class="leftDiv">
                        <p>
                            <span class="spanOne" >验收结果: </span>
                            <span class="spanTwo" >{{item.dealResult ? item.dealResult : '暂无' }}</span>
                        </p>
                        <p v-if="item.dealResultValue=='0'">
                            <span class="spanOne">最终费用: </span>
                            <span class="spanThree">{{item.fee ? item.fee+'元' : '暂无' }}</span>
                        </p>
                    </div>
                    <div class="rightDiv">
                        <p v-if="item.dealResultValue=='0'">
                            <span class="spanOne" >联系方式: </span>
                            <span class="spanTwo" >{{item.changeStaffMobile ? item.changeStaffMobile : '暂无' }}</span>
                        </p>
                        <p>
                            <span class="spanOne" >验收人: </span>
                            <span class="spanTwo" >{{item.changeStaffName ? item.changeStaffName : '暂无' }}</span>
                        </p>

                    </div>
                </div>
                <div class="divTwo">
                    <p v-if="item.dealResultValue=='0'">
                        <span class="spanOne">费用承担方: </span>
                        <span class="spanTwo">{{item.costBear ? item.costBear : '暂无' }}</span>
                    </p>
                    <p>
                        <span class="spanOne">验收时间: </span>
                        <span class="spanThree">{{item.addTime ? item.addTime : '暂无' }}</span>
                    </p>
                    <p v-if="item.dealResultValue=='1'">
                        <span class="spanOne">驳回原因: </span>
                        <span class="spanTwo">{{item.refuseReason ? item.refuseReason : '暂无' }}</span>
                    </p>
                    <div  class="evaluate" v-if="item.dealResultValue=='0'">
                        <span class="spanOne">服务人员评价: </span>
                        <div class="evaluateImg"   >
                            <van-rate v-model="item.evaluateStar"  :count="5" color="#FF5D3B" size="17px" readonly/>
                        </div>
                    </div>
                    <p>
                        <span class="spanOne">验收备注: </span>
                        <span class="spanTwo">{{item.remarks ? item.remarks : '暂无' }}</span>
                    </p>
                </div>
            </div>
        </div>
        <!--       验收DIV标题 -->
        <div class="dispatchText"  v-if="checkImgList.length>0">验收通过图片</div>
        <!--     验收记录的图片  -->
        <div class="applyDetailImgs" v-if="checkImgList.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in checkImgList" :key="item.id">
                    <div class="applyDetailImg">
                    <img  :src="item" @click="onChangeCheck(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{checkImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showCheck" :images="checkImgList"  :start-position="start"></van-image-preview>
        <!--       验收DIV标题 -->
        <div class="dispatchText"  v-if="rejectCeckImgList.length>0">验收驳回图片</div>
        <!--     验收记录的图片  -->
        <div class="applyDetailImgs" v-if="rejectCeckImgList.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in rejectCeckImgList" :key="item.id">
                    <div class="applyDetailImg">
                        <img  :src="item" @click="onChangeCheckReject(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{rejectCeckImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showCheckReject" :images="rejectCeckImgList"  :start-position="startReject"></van-image-preview>
    </div>

</template>

<script>
    import BScroll from 'better-scroll';
    import { NavBar,Swipe,SwipeItem, Image as VanImage, ImagePreview,rate} from 'vant';
    import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import {cleanDetails} from "../../../getData/getData";
    import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            RxNavBar,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [NavBar.name]: NavBar,
            [VanImage.name]: VanImage,
            [rate.name]: rate,
            [ImagePreview.Component.name]:ImagePreview.Component,

        },

        created() {
            this.afterRent_id = this.$route.query.afterRent_id
            this.cleanId = this.$route.query.cleanId
            this.cleanDetails()
        },
        methods:{
            //保洁详情初始化
            cleanDetails() {
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id = that.cleanId
                initData.afterRent_id = that.afterRent_id
                cleanDetails(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.applyCleanDetails = response.data.data
                        console.log(that.applyCleanDetails)
                        //申请详情的图片
                        for(let a=0;a<response.data.data.applayImgList.length;a++){
                            that.applayImgList.push(response.data.data.applayImgList[a].path)
                        }
                        that.applayImgList.reverse()
                        //验收详情的图片通过
                        for(let a=0;a<response.data.data.checkImgList.length;a++){
                            that.checkImgList.push(response.data.data.checkImgList[a].path)
                        }
                        that.checkImgList.reverse()
                        //完成详情的图片
                        if(response.data.data.finishImgList.length>0){
                            for(let a=0;a<response.data.data.finishImgList.length;a++){
                                that.finishImgList.push(response.data.data.finishImgList[a].path)
                            }
                            that.finishImgList.reverse()
                        }
                        //验收详情的图片驳回
                        if(response.data.data.rejectCeckImgList.length>0){
                            for(let a=0;a<response.data.data.rejectCeckImgList.length;a++){
                                that.rejectCeckImgList.push(response.data.data.rejectCeckImgList[a].path)
                            }
                            that.rejectCeckImgList.reverse()
                        }
                        //评价级别数字类型转化
                        for(let i=0;i<that.applyCleanDetails.afterrentHisotryList.length;i++){
                            that.applyCleanDetails.afterrentHisotryList[i].evaluateStar= parseInt(that.applyCleanDetails.afterrentHisotryList[i].evaluateStar)
                        }
                    })
                })
            },
            previewImg(start){
                ImagePreview(
                    {
                        images: this.imgList,
                        startPosition: start,
                        onClose() {
                            // do something
                        },
                    }
                )
            },
            onChangeApply(i) {
                this.start=i
                this.showApply=true
            },
            onChangeFinished(i) {
                this.start=i
                this.showFinished=true
            },
            onChangeCheck(i) {
                this.start=i
                this.showCheck=true
            },
            onChangeCheckReject(i) {
                this.startReject=i
                this.showCheckReject=true
            },
        },
        data(){
            return{
                //查看详情
                applyCleanDetails : {
                    afterrentHisotryList :[],
                    pieList: []
                },
                //申请详情图片path
                applayImgList : [],
                //验收详情图片path通过
                checkImgList : [],
                //验收详情图片path驳回
                rejectCeckImgList : [],
                //完成详情图片path
                finishImgList : [],
                // 预览图索引
                start : '',
                //
                showApply : false,
                showFinished : false,
                showCheck : false,
                changeGlobalMobile : '',
                //驳回
                showCheckReject : false,
                startReject : ''
            }
        },
    }

</script>

<style scoped lang="less">
    *{
        padding: 0;
        margin: 0;
    }
    /*    申请详情图片DIV*/
    .applyDetailImgs{
        /*position: relative;*/
        height: 108px;
        width: 92%;
        margin: 0 auto;
        background-color: #FFFFFF;
        border-radius: 8px;
        /*图片*/
        .applyDetailImg{
            width: 100px;
            height: 75px;
            border-radius: 8px;
            margin: 15px 0px 18px 10px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e1eaec ;

            img{
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        /*    轮播的显示图片数*/
        .custom-indicator{
            position: absolute;
            left: 15px;
            top: 20px;
            padding: 1px 7px;
            font-size: 12px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            color: white;
        }
    }
    .dispatchText{
        width: 2rem;
        height: 0.64rem;
        font-size: 0.32rem;
        color: #e96b48;
        font-weight: bold;
        margin-left: 0.5rem;
        margin-top: 10px;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }
    .broadBandDetails{
        height: auto;
        padding-bottom: 20px;
    }
    .broadBandDetails-Apply{
        background-color: white;
        width: 92%;
        margin: 0 auto 15px;
        border-radius: 8px;
        padding-bottom: 10px;
        .addressInfo{
            width: 92%;
            margin: 0 auto;
            height: 40px;
            font-size: 15px;
            line-height: 40px;
            font-weight: bold;
        }
        .rule{
            width: 100%;
            border: 0.5px solid #e6e6e6;
            margin-bottom: 10px;
        }
    }
    .broadBandDetails-dispatch{
        background-color: white;
        margin: 0 auto 15px;
        width: 92%;
        border-radius: 8px;
        padding-top:10px ;
        padding-bottom: 10px;
    }
    .title{
        font-size: 12px;
        width: 92%;
        color: #FF5D3B ;
        font-weight: bold;
    }
    /*验收详情DIV*/
    .applyDetail{
        width: 92%;
        background-color: #FFFFFF ;
        margin: 0 auto 15px;
        border-radius: 8px;
        padding-bottom: 15px;
        padding-top: 15px;
        /*    地址DIV*/
        .addressInfo{
            font-size: 15px;
            font-weight: bold;
            margin:0px 15px 15px;
        }
        /*   分割线 */
        .rule{
            width: 100%;
            border: 0.5px solid #EFEFEF;
            margin-bottom: 15px;
        }
    }
      //公共主体内容DIV
    .divOne{
        margin: 0 15px ;
        display: flex;
        font-size: 14px;
        line-height: 24px;
        .leftDiv{
            width: 45%;
        }
        .rightDiv{
            width: 55%;
        }
    }
    .divTwo{
        margin: 0 15px;
        font-size: 14px;
        line-height: 24px;
        overflow: hidden;
        word-wrap: break-word;
        .evaluate{
            width: 100%;
            display: flex;
            align-items: center;
            .evaluateImg{
                margin-left: 20px;
                margin-top: 5px;
            }
            .spanOne{
                font-weight: bold;
            }
        }
    }
    //公共P标签
    p{
        line-height: 30px;
        .spanOne{
            font-weight: bold;
        }
        .spanTwo{
            color: #9a9a9a;
            font-size: 14px;
            line-height: 24px;
        }
        .spanThree{
            color: #FF5D3B;
            font-size: 13.5px;
        }
    }

</style>
